import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {TEACHER_WISE, TEACHER_WISE_API_URL, GET_ALL_TEACHER_WISE_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  TeacherWiseErrors       : {},
  permission: permission?.teacher_wise ?? [],
  TeacherWiseShowMessage  : null,
  TeacherWiseResult       : [],
  TeacherWiseButtonSpinner : false,
  TeacherWiseTableLoading : true,
  TeacherWiseAddDrawer    : false,
  TeacherWiseEditData     : [],
  TeacherWiseButtonAndModelLabel  : setLocale('teacherwise.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewTeacherWiseData: [],
    ViewTeacherWiseLoader: true,
    DrawerStatus: 0,
};

export const createTeacherWise = createAsyncThunk(
    "createTeacherWise",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, TEACHER_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTeacherWise = createAsyncThunk(
    "getTeacherWise",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_TEACHER_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewTeacherWise = createAsyncThunk(
  "viewTeacherWise",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, TEACHER_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteTeacherWise = createAsyncThunk(
  "deleteTeacherWise",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, TEACHER_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageTeacherWiseSlice = createSlice({
  name: TEACHER_WISE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.TeacherWiseErrors = {};
    },
    TeacherWiseAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.TeacherWiseButtonSpinner = false;
      }
      state.TeacherWiseAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.TeacherWiseEditData    = [];
      state.TeacherWiseButtonAndModelLabel = setLocale('teacherwise.add');
    },
    TeacherWiseEditWithDrawerStatus: (state, action) => {
      state.TeacherWiseAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.TeacherWiseEditData = action.payload.data;
      state.TeacherWiseButtonAndModelLabel = setLocale('teacherwise.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeacherWise.pending, (state, action) => {
        state.DrawerStatus = 0
        state.TeacherWiseButtonSpinner = true;
      }).addCase(createTeacherWise.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.TeacherWiseButtonSpinner = false;
        state.TeacherWiseErrors = {}
      }).addCase(createTeacherWise.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.TeacherWiseShowMessage = true;
        state.TeacherWiseButtonSpinner = false;
        state.TeacherWiseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getTeacherWise.pending, (state, action) => {
        state.TeacherWiseButtonSpinner = true;
        state.TeacherWiseTableLoading = true;
      }).addCase(getTeacherWise.fulfilled, (state, action) => {
        state.TeacherWiseButtonSpinner = false;
        state.TeacherWiseTableLoading = false;
        state.TeacherWiseResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getTeacherWise.rejected, (state, action) => {
        state.TeacherWiseShowMessage = true; // Set the showMessage flag to display the errors
        state.TeacherWiseButtonSpinner = false;
        state.TeacherWiseTableLoading = false;
        state.TeacherWiseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewTeacherWise.pending, (state, action) => {
        state.ViewTeacherWiseLoader = true;
        state.ViewTeacherWiseData = [];
      }).addCase(viewTeacherWise.fulfilled, (state, action) => {
        state.ViewTeacherWiseLoader = false;
        state.ViewTeacherWiseData = action.payload;
      }).addCase(viewTeacherWise.rejected, (state, action) => {
        state.ViewTeacherWiseLoader = false;
        state.ViewTeacherWiseData = [];
      })
      .addCase(deleteTeacherWise.pending, (state, action) => {
        state.TeacherWiseTableLoading = true;
      }).addCase(deleteTeacherWise.fulfilled, (state, action) => {
        state.TeacherWiseTableLoading = false;
      }).addCase(deleteTeacherWise.rejected, (state, action) => {
        state.TeacherWiseTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, TeacherWiseAddDrawerStatus, TeacherWiseEditWithDrawerStatus, updateSortFilters } = manageTeacherWiseSlice.actions;

export default manageTeacherWiseSlice.reducer;
