import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CLASS_WISE, CLASS_WISE_API_URL, GET_ALL_CLASS_WISE_API_URL, EXPORT_STUDENT_CLASS_WISE_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ClasswiseErrors: {},
  permission: permission?.class_wise ?? [],
  ClasswiseShowMessage: null,
  ClasswiseResult: [],
  ClasswiseButtonSpinner: false,
  ClasswiseTableLoading: true,
  ClasswiseAddDrawer: false,
  ClasswiseEditData: [],
  ClasswiseButtonAndModelLabel: setLocale('classwise.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewClasswiseData: [],
  ViewClasswiseLoader: true,
  DrawerStatus: 0,
  exportButtonLoader: false,
};

export const createClasswise = createAsyncThunk(
  "createClasswise",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CLASS_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getClasswise = createAsyncThunk(
  "getClasswise",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CLASS_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewClasswise = createAsyncThunk(
  "viewClasswise",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CLASS_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteClasswise = createAsyncThunk(
  "deleteClasswise",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CLASS_WISE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const exportStudentsClassWise = createAsyncThunk('exportStudentsClassWise', async (data) => {
  try {
    return await CommonService.postData(data, EXPORT_STUDENT_CLASS_WISE_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});

export const manageClasswiseSlice = createSlice({
  name: CLASS_WISE,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ClasswiseErrors = {};
    },
    ClasswiseAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ClasswiseButtonSpinner = false;
      }
      state.ClasswiseAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ClasswiseEditData = [];
      state.ClasswiseButtonAndModelLabel = setLocale('classwise.add');
    },
    ClasswiseEditWithDrawerStatus: (state, action) => {
      state.ClasswiseAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ClasswiseEditData = action.payload.data;
      state.ClasswiseButtonAndModelLabel = setLocale('classwise.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClasswise.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ClasswiseButtonSpinner = true;
      }).addCase(createClasswise.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ClasswiseButtonSpinner = false;
        state.ClasswiseErrors = {}
      }).addCase(createClasswise.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ClasswiseShowMessage = true;
        state.ClasswiseButtonSpinner = false;
        state.ClasswiseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getClasswise.pending, (state, action) => {
        state.ClasswiseButtonSpinner = true;
        state.ClasswiseTableLoading = true;
      }).addCase(getClasswise.fulfilled, (state, action) => {
        state.ClasswiseButtonSpinner = false;
        state.ClasswiseTableLoading = false;
        state.ClasswiseResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getClasswise.rejected, (state, action) => {
        state.ClasswiseShowMessage = true; // Set the showMessage flag to display the errors
        state.ClasswiseButtonSpinner = false;
        state.ClasswiseTableLoading = false;
        state.ClasswiseErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewClasswise.pending, (state, action) => {
        state.ViewClasswiseLoader = true;
        state.ViewClasswiseData = [];
      }).addCase(viewClasswise.fulfilled, (state, action) => {
        state.ViewClasswiseLoader = false;
        state.ViewClasswiseData = action.payload;
      }).addCase(viewClasswise.rejected, (state, action) => {
        state.ViewClasswiseLoader = false;
        state.ViewClasswiseData = [];
      })
      .addCase(deleteClasswise.pending, (state, action) => {
        state.ClasswiseTableLoading = true;
      }).addCase(deleteClasswise.fulfilled, (state, action) => {
        state.ClasswiseTableLoading = false;
      }).addCase(deleteClasswise.rejected, (state, action) => {
        state.ClasswiseTableLoading = false;
      })
      .addCase(exportStudentsClassWise.pending, (state, action) => {
        state.exportButtonLoader = true;
      }).addCase(exportStudentsClassWise.fulfilled, (state, action) => {
        const fileUrl = action.payload.file_url; // Get the file URL from the response

        // Create a hidden anchor tag to initiate download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'students_classwise.xlsx'); // Specify the file name

        // Append the anchor to the body, trigger the click, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        state.exportButtonLoader = false;
      }).addCase(exportStudentsClassWise.rejected, (state, action) => {
        state.exportButtonLoader = false;
      });
  },
});

export const { onCloseError, setColumnSearch, ClasswiseAddDrawerStatus, ClasswiseEditWithDrawerStatus, updateSortFilters } = manageClasswiseSlice.actions;

export default manageClasswiseSlice.reducer;
